import React, { useRef, useEffect } from "react";
import "../../styles/home.scss";

export default function Separator() {
    return (
        <div>
            <div className="l__separator desktop-only">
                <div className="separator__top"
                     style={{transform: 'translate(0%, 7.8027%) translate3d(0px, 0px, 0px) rotate(180deg) skew(360deg, 0deg)'}}
                >
                    <p  data-scroll
                        data-scroll-direction="vertical"
                        data-scroll-speed="7"
                        data-scroll-class="appear"
                        data-scroll-repeat="true"

                        className="separator__text">

                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                    </p>
                </div>

                <div className="separator__bottom"
                     style={{transform: 'translate(0%, -7.8027%)   translate3d(0px, 0px, 0px)'}}
                >
                    <p data-scroll
                       data-scroll-direction="vertical"
                       data-scroll-speed="-7"
                       data-scroll-class="appear"
                       data-scroll-repeat="true"
                       className="separator__text">

                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                        <span>DEMOCRATIC</span>
                        <span>PERMISSIONLESS</span>
                    </p>
                </div>

            </div>
        </div>
    );
}
